<template>
  <div>
    <v-simple-table class="table mt-2" height="81vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Product</th>
            <th class="text-left">Variant</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Current Qty</th>
            <th class="text-left">Stores</th>
            <th class="text-left">Track</th>
            <th class="text-left">Date/Time</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in inventories" :key="i">
            <td>Beras</td>
            <td>All</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.total }}</td>
            <td>Pickup, Delivery, Dine-in</td>
            <td>{{ item.track }}</td>
            <td>{{ returnDate(item.createdAt) }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="blue" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on">
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Impersonate</span>
                </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "Inventory",
  data() {
    return {};
  },

  methods: {
    returnDate(i) {
      return dayjs(i).format("DD/MM/YYYY h:mmA");
    },
  },

  computed: {
    ...mapGetters({
      inventories: "getInventory",
    }),
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchInventory");
  },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
